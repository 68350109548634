import { Timestamp } from 'firebase/firestore';
import {
  ContractLength,
  DeveloperExperience,
  DeveloperRole,
  EnglishLevel,
} from '../../../../types/TeamCalculator/Enums';

type DeveloperRoleKeyType = keyof typeof DeveloperRole.en;

const imagesByRole = {
  '': '/avatars/placeholder.png',
  [DeveloperRole.en.frontendDeveloper as DeveloperRoleKeyType]: '/avatars/frontendDeveloper.png',
  [DeveloperRole.en.backendDeveloper as DeveloperRoleKeyType]: '/avatars/backendDeveloper.png',
  [DeveloperRole.en.projectManager as DeveloperRoleKeyType]: '/avatars/projectManager.png',
  [DeveloperRole.en.qa as DeveloperRoleKeyType]: '/avatars/qa.png',
  [DeveloperRole.en.designer as DeveloperRoleKeyType]: '/avatars/uiuxDesigner.png',
  [DeveloperRole.en.devOps as DeveloperRoleKeyType]: '/avatars/devops.png',
  [DeveloperRole.en.techLead as DeveloperRoleKeyType]: '/avatars/teamLead.png',
  [DeveloperRole.en.blockchainDeveloper as DeveloperRoleKeyType]: '/avatars/blockchain.png',
  [DeveloperRole.en.dataEngineer as DeveloperRoleKeyType]: '/avatars/dataEngineer.png',
  [DeveloperRole.en.cyberSecurity as DeveloperRoleKeyType]: '/avatars/cyberSecurity.png',
  [DeveloperRole.en.dataScientist as DeveloperRoleKeyType]: '/avatars/dataScientist.png',
  [DeveloperRole.en.businessIntelligence as DeveloperRoleKeyType]:
    '/avatars/businessIntelligence.png',
};

const daysInMonth = 20;
const weeksInMonth = 4;

export type Constants = {
  id: string;
  costsByHourByRole: {
    [key in DeveloperRoleKeyType]: number;
  };
  discountPercentByExperience: {
    [key in keyof typeof DeveloperExperience.en]: number;
  };
  discountPercentByEnglishLevel: { [key in keyof typeof EnglishLevel.en]: number };
  discountPercentByProjectLength: {
    [key in keyof typeof ContractLength]: number;
  };
  startsAt?: Timestamp;
  finishesAt?: Timestamp;
  conditions: string[];
  draft: string[];
  minimumHours?: number;
};

export { imagesByRole, daysInMonth, weeksInMonth };
