import styled, { css } from 'styled-components';
import { blue } from '../../../styles/colors';
import media from '../../../styles/media';

const colors = {
  primary: {
    background: blue,
    h1: 'white',
    input: {
      color: 'white',
      'border-color': 'white',
      placeholder: 'white',
    },
    button: {
      'background-color': 'white',
      color: blue,
    },
  },
  white: {
    background: 'white',
    h1: blue,
    input: {
      color: blue,
      'border-color': blue,
      placeholder: blue,
    },
    button: {
      'background-color': blue,
      color: 'white',
    },
  },
};

export const BudgetFormWrapper = styled.div<{ background: 'primary' | 'white' }>`
  /* background: linear-gradient(45deg, rgba(8, 184, 255, 1) 0%, rgba(86, 220, 255, 1) 100%); */
  ${({ background }) => css`
    background-color: ${colors[background].background};

    h1 {
      text-align: center;
      font-size: 34px;
      line-height: 1.2em;
      font-weight: lighter;
      margin-top: 0px;
      margin-bottom: 30px;
      color: ${colors[background].h1};
    }

    form {
      max-width: 1200px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: auto;
      gap: 30px;

      input {
        height: 45px;
        width: 100%;
        color: ${colors[background].input.color};
        font-size: 18px;
        font-weight: lighter;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid ${colors[background].input['border-color']};
        background-color: transparent;
        border-radius: 5px;

        &::placeholder {
          color: ${colors[background].input.placeholder} !important;
          opacity: 1;
        }

        &:-webkit-autofill {
          -webkit-background-clip: text;
          -webkit-text-fill-color: ${background === 'primary' ? 'white' : 'black'};
          background-clip: text;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        justify-self: flex-end;
        grid-column-end: 4;
        padding: 10px 20px;
        font-size: 1em;
        width: fit-content;
        font-weight: 400;
        transition: color 0.3s ease-out;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        transition: 0.2s;
        background-color: ${colors[background].button['background-color']};
        color: ${colors[background].button.color};

        &:hover {
          filter: brightness(0.9);

          &::after {
            background-image: unset;
          }
        }

        svg {
          margin-top: -2px;
          margin-right: 4px;
        }
      }

      ${media('large', true)} {
        display: flex;
        flex-direction: column;
      }
    }
  `}
`;

export const FormErrorMessage = styled.span``;
