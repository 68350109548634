/* eslint-disable camelcase */
import { Timestamp, Unsubscribe } from 'firebase/firestore';
import { useRouter } from 'next/router';
import React from 'react';
import { createOrUpdateItem, setListListener, createRef } from '../../../utils/firebase';
import Budget from '../../../types/TeamCalculator/Budget';
import BudgetFormTemplate from './template';

type BudgetFormProps = {
  background?: 'white' | 'primary';
  title?: string;
  submitText?: string;
  disableNavigate?: boolean;
  afterSubmit?: () => void;
};

export type FormFields = {
  name: string;
  email: string;
  title: string;
};

const BudgetForm: React.FC<BudgetFormProps> = ({ disableNavigate, afterSubmit, ...props }) => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [budgets, setBudgets] = React.useState([] as Budget[]);
  const router = useRouter();

  const unsubBudget = React.useRef<null | Unsubscribe>(null);

  React.useEffect(() => {
    setListListener(['budgets'], setBudgets, unsubBudget);

    return () => {
      if (unsubBudget.current) {
        unsubBudget.current();
      }
    };
  }, []);

  const emailExists = (budget: Budget, email: string) => {
    return !!budget.emailAddresses?.find((emailAddress) => emailAddress === email);
  };

  const onSubmit = async (data: FormFields) => {
    try {
      setError(false);
      setLoading(true);
      const budgetExists = budgets.find((budget) => {
        if (budget.title === data.title) {
          if (!emailExists(budget, data.email)) {
            createOrUpdateItem(
              ['budgets'],
              {
                emailAddresses: [...(budget.emailAddresses || []), data.email],
              },
              budget.id,
              true,
            );
          }
          return true;
        }

        if (emailExists(budget, data.email)) return true;

        return false;
      });

      let budgetId = '';

      if (budgetExists) {
        budgetId = budgetExists.id;
        if (!disableNavigate) window.open(`/team-calculator?hash=${budgetExists.id}`, '__blank');
      } else {
        const budgetRef = await createRef(['budgets']);
        await createOrUpdateItem(
          ['budgets'],
          {
            responsibleName: data.name,
            emailAddresses: [data.email],
            title: data.title,
            adjustmentRate: 1,
            createdAt: Timestamp.fromDate(new Date()),
            language: 'en',
            currency: 'usd',
          },
          budgetRef.id,
        );
        budgetId = budgetRef.id;
        if (!disableNavigate) window.open(`/team-calculator?hash=${budgetRef.id}`, '__blank');
      }

      localStorage.setItem(`allowed-${budgetId}`, 'true');

      setLoading(false);
    } catch {
      setError(true);
    } finally {
      afterSubmit?.();
    }
  };

  return (
    <BudgetFormTemplate
      {...props}
      background={props.background || 'primary'}
      onSubmit={onSubmit}
      locale={router.locale as string}
      loading={loading}
      error={error}
    />
  );
};

export default BudgetForm;
