import styled from 'styled-components';

const Select = styled.select`
  font-size: 1em;
  font-weight: 400;
  min-height: 42px !important;
  transition: border-color 0.2s ease-in-out 0s;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 8px;
  border-radius: 6px;
  &:hover {
    border-color: ${(props) => props.theme.colors.blue};
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blue};
  }
`;

export default Select;
