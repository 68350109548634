import React from 'react';
import { useForm } from 'react-hook-form';
import { FormFields } from '.';
import { LoadingIcon } from '../LoadingIcon';
import { BudgetFormWrapper, FormErrorMessage } from './styles';

export type BudgetFormTemplateProps = {
  onSubmit?: ({ name, email, title }: { name: string; email: string; title: string }) => void;
  locale: string;
  error?: boolean;
  loading?: boolean;
  title?: string;
  submitText?: string;
  background: string;
};

const texts = {
  pt: {
    name: 'Nome',
    email: 'E-mail',
    title: 'Empresa',
    send: 'Abrir simulador de orçamento',
    error: 'Ocorreu um erro ao enviar o formulário',
    h1: 'Simule o orçamento para o seu projeto',
  },
  en: {
    name: 'Name',
    email: 'Email',
    title: 'Company',
    send: 'Open budget simulator',
    error: 'An error occurred on submitting your answers',
    h1: 'Simulate the budget for your project',
  },
};

const BudgetFormTemplate: React.FC<BudgetFormTemplateProps> = ({
  locale,
  loading,
  error,
  background,
  title,
  submitText,
  onSubmit,
}) => {
  const { handleSubmit, register } = useForm();
  const text = texts[locale as 'pt' | 'en'];

  return (
    <BudgetFormWrapper background={background as 'white' | 'primary'}>
      <h1>{title || text.h1}</h1>
      <form
        name="budget-form"
        onSubmit={onSubmit && handleSubmit((data) => onSubmit(data as FormFields))}
      >
        <input
          placeholder={text.name}
          type="text"
          {...register('name')}
          id="name"
          required
          autoFocus
        />
        <input placeholder={text.title} {...register('title')} type="text" id="title" required />
        <input placeholder={text.email} type="email" {...register('email')} id="email" required />
        {error && <FormErrorMessage id="error">{text.error}</FormErrorMessage>}
        <button disabled={loading} type="submit">
          {loading && <LoadingIcon />}
          {submitText || text.send}
        </button>
      </form>
    </BudgetFormWrapper>
  );
};

export default BudgetFormTemplate;
