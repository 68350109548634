import Budget from '../../../../types/TeamCalculator/Budget';
import { TimeGroup, WorkloadType } from '../../../../types/TeamCalculator/Enums';
import TeamItem from '../../../../types/TeamCalculator/TeamItem';
import { daysInMonth, weeksInMonth } from './consts';

const getHoursByWorkloadType = (workloadType: keyof typeof WorkloadType.en, monthly?: boolean) => {
  // For now, we'll show one value, but  use another one for the multiplication.
  // This way, the monthly value can be lower and match the reality.
  switch (workloadType) {
    case WorkloadType.en.fullTime:
      return monthly ? 25 : 32;
    case WorkloadType.en.partTime:
      return monthly ? 12.5 : 16;
    case WorkloadType.en.onceWeekly:
      return monthly ? 5 : 6.5;
    default:
      return 0;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseFormDataToType = (formData: any) => {
  return {
    id: formData.id,
    localId: formData.localId,
    createdAt: formData.createdAt,
    ...(formData.workloadType && { workloadType: formData.workloadType }),
    ...(formData.englishLevel && { englishLevel: formData.englishLevel }),
    ...(formData.minimumExperience && { minimumExperience: formData.minimumExperience }),
    ...(formData.role && { role: formData.role }),
    ...(formData.workload && { workload: formData.workload }),
    ...(formData.currentCost && { currentCost: formData.currentCost }),
    ...(formData.quantity && { quantity: parseInt(formData.quantity) }),
    ...(formData.free !== undefined && { free: formData.free }),
  } as TeamItem;
};

const FormattedValue: React.FC<{ value: string }> = ({ value }) => {
  const splitted = value.split(/\s/g);

  const code = splitted[0];
  const number = splitted[1];

  return (
    <>
      <small style={{ fontSize: '0.8em', marginRight: 4 }}>{code}</small>
      {number}
    </>
  );
};

const getCurrency = (locale: string, currency: Budget['currency'], value: number) => {
  const formattedValue = new Intl.NumberFormat(locale ? locale : 'en-US', {
    style: 'currency',
    currency: currency ? currency : 'usd',
    currencyDisplay: !currency || currency === 'usd' ? 'code' : 'symbol',
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(value);

  return <FormattedValue value={formattedValue} />;
};
const getCurrencyWithFractional = (locale: string, currency: Budget['currency'], value: number) => {
  const formattedValue = new Intl.NumberFormat(locale ? locale : 'en-US', {
    style: 'currency',
    currency: currency ? currency : 'usd',
    currencyDisplay: !currency || currency === 'usd' ? 'code' : 'symbol',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);

  return <FormattedValue value={formattedValue} />;
};

const filterValidRow = (row: TeamItem, filterFree?: boolean) => {
  const test = row.role && row.minimumExperience && row.quantity && row.workloadType;
  if (filterFree) {
    return test && !row.free;
  }
  return test;
};

const isEmptyRow = (row: TeamItem) => {
  return !row.role && !row.minimumExperience && !row.quantity && !row.workloadType;
};

const getCostByTimeGroup = (
  monthlyCost: number,
  workloadType: keyof typeof WorkloadType.en,
  timeGroup: keyof typeof TimeGroup.en,
) => {
  const hoursPerWeek = getHoursByWorkloadType(
    WorkloadType.en[workloadType] as keyof typeof WorkloadType.en,
    true,
  );

  switch (timeGroup) {
    case 'monthly':
      return monthlyCost;
    case 'weekly':
      return monthlyCost / weeksInMonth;
    case 'daily':
      return monthlyCost / daysInMonth;
    case 'hourly':
      return monthlyCost / weeksInMonth / hoursPerWeek;
  }
};

export {
  getHoursByWorkloadType,
  parseFormDataToType,
  filterValidRow,
  isEmptyRow,
  getCostByTimeGroup,
  getCurrency,
  getCurrencyWithFractional,
};
