const DeveloperRole = {
  pt: {
    backendDeveloper: 'Desenvolvedor Backend',
    frontendDeveloper: 'Desenvolvedor Frontend',
    blockchainDeveloper: 'Desenvolvedor Blockchain',
    projectManager: 'Gerente de Projeto',
    designer: 'UI/UX Designer',
    devOps: 'DevOps',
    qa: 'Q&A',
    techLead: 'Líder Técnico',
    dataEngineer: 'Engenheiro de Dados',
    businessIntelligence: 'Business Intelligence',
    dataScientist: 'Cientista de Dados',
    cyberSecurity: 'Cyber Security',
  },
  en: {
    backendDeveloper: 'Backend Developer',
    frontendDeveloper: 'Frontend Developer',
    blockchainDeveloper: 'Blockchain Developer',
    projectManager: 'Project Manager',
    designer: 'UI/UX Designer',
    devOps: 'DevOps',
    qa: 'Q&A',
    techLead: 'Tech Lead',
    dataEngineer: 'Data Engineering',
    businessIntelligence: 'Business Intelligence',
    dataScientist: 'Data Scientist',
    cyberSecurity: 'Cyber Security',
  },
};

const DeveloperExperience = {
  pt: {
    junior: 'Júnior',
    mid: 'Pleno',
    senior: 'Sênior',
  },
  en: {
    junior: 'Junior',
    mid: 'Middle',
    senior: 'Senior',
  },
};

const EnglishLevel = {
  en: {
    none: 'None',
    intermediate: 'Intermediate',
    advanced: 'Advanced/Fluent',
  },
  pt: {
    none: 'Nenhum',
    intermediate: 'Intermediário',
    advanced: 'Avançado/Fluente',
  },
};

const WorkloadType = {
  pt: {
    fullTime: 'Tempo Integral',
    partTime: 'Meio Período',
    onceWeekly: 'Uma vez por semana',
  },
  en: {
    fullTime: 'Full Time',
    partTime: 'Part-Time',
    onceWeekly: 'Once Weekly',
  },
};

enum ContractLength {
  three = '3',
  six = '6',
  nine = '9',
  twelve = '12',
  eighteen = '18',
  twentyFour = '24',
  thirty = '30',
  thirtySix = '36',
}

const TimeGroup = {
  pt: {
    monthly: 'mensal',
    weekly: 'semanal',
    daily: 'diário',
    hourly: 'por hora',
  },
  en: {
    monthly: 'monthly',
    weekly: 'weekly',
    daily: 'daily',
    hourly: 'hourly',
  },
};

enum Currency {
  brl = 'BRL',
  usd = 'USD',
  eur = 'EUR',
}

enum CurrencyLocale {
  brl = 'pt-BR',
  usd = 'en-US',
  eur = 'pt',
}

export {
  DeveloperExperience,
  DeveloperRole,
  EnglishLevel,
  WorkloadType,
  ContractLength,
  TimeGroup,
  Currency,
  CurrencyLocale,
};
