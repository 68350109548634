import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Timestamp } from 'firebase/firestore';
import 'dayjs/locale/en';
import 'dayjs/locale/pt-br';

dayjs.extend(customParseFormat);

export const formatTimestamp = (timestamp: Timestamp): string => {
  const date = dayjs(timestamp.toDate());

  return date.format(`MMMM DD, YYYY`);
};
